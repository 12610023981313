<template>
  <div class="container-fluid">
    <div class="row vh-100">
      <div class="col-md-2 text-left">
        <div class="side-nav shadow-sm p-2 mb-5 bg-white rounded">
          <div class="text-center">
            <b-img :src="logo" fluid alt="Logo" class="logo pt-3"></b-img>
          </div>
          <div class="menu mt-4">
            <div class="text-center">
              <label class="title">Administración</label>
            </div>
            <b-nav vertical class="w-100 font-weight-light mt-4">
              <b-nav-item to="/events" active>
                <b-icon icon="columns-gap" variant="primary"></b-icon>
                <label class="ml-2">Eventos</label>
              </b-nav-item>
              <b-nav-item v-on:click="logout">
                <b-icon icon="box-arrow-right" variant="danger"></b-icon>
                <label class="ml-2 text-danger">Cerrar sesión</label>
              </b-nav-item>
            </b-nav>
          </div>
        </div>
      </div>
      <div class="col-md-10 text-left p-5">
        <div>
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import logo from "@/assets/images/logo-cms.png";
export default {
  name: "DashboardPage",
  data() {
    return {
      logo,
    };
  },
  methods: {
    async logout() {
      await this.$store.dispatch("auth/logout");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container-fluid {
  background: linear-gradient(180deg, rgb(21, 96, 49) 20%, #F3F2EF 0%);
}
.side-nav {
  background-color: #FFF;
  border-radius: 10px;
  margin-top: 15px;
  height: 95%;
  max-height: 100vh;
}
.logo {
  width: 100px;
}
.title {
  color: #696969;
  font-size: 1rem;
  font-weight: bold;
}
</style>
