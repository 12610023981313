<template>
  <div class="categories">
    <div v-if="!showDetails">
      <div class="row justify-content-between align-middle">
        <label class="title font-weight-bold">Eventos</label>
      </div>
      <div class="row actions mt-4 shadow p-3 mb-5 bg-white rounded">
        <div class="actions-left">
          <b-input-group>
            <b-input-group-prepend is-text>
              <b-icon icon="search"></b-icon>
            </b-input-group-prepend>
            <b-form-input
              type="search"
              v-model="filter"
              placeholder="Buscar..."
            ></b-form-input>
          </b-input-group>
        </div>
        <div class="actions-right text-right">
        </div>
      </div>
      <div class="row content mt-4 shadow p-3 mb-5 bg-white rounded">
        <div class="description">
          <b-icon icon="info-square" variant="info" class="mr-1" />
          <label>
            Listado de Eventos y número de participantes registrados.
          </label
          >
        </div>
        <b-table
          small
          bordered
          hover
          :items="items"
          :fields="fields"
          class="mt-4"
          :per-page="perPage"
          :current-page="currentPage"
          :busy="isBusy"
          show-empty
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          @filtered="onFiltered"
          ref="categoriesTable"
          responsive
          tbody-tr-class="table-row"
        >
          <template #thead-top>
            <b-tr class="theader">
              <b-th colspan="5">
                <b-icon
                  icon="columns-gap"
                  class="icon rounded-circle p-2"
                  variant="secondary"
                ></b-icon>
                <label class="total">{{ totalRows }}</label>
                <label class="total-title">Eventos</label>
              </b-th>
            </b-tr>
          </template>
          <template #cell(eventTitle)="row">
            <b-link :href="getUrl(row)">{{ row.value }}</b-link>
          </template>
          <template #cell(eventDate)="row">
            {{ formatDate(row.value) }}
          </template>
          <template #table-busy>
            <div class="text-center text-info my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Cargando...</strong>
            </div>
          </template>
          <template #empty>
            <div class="text-center m-4">No se encontraron resultados</div>
          </template>
        </b-table>
        <b-pagination
          pills
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="right"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { getEvents } from "@/api/events";
import moment from "moment-timezone";

export default {
  name: "EventList",
  components: {},
  data() {
    return {
      showDetails: false,
      filter: null,
      isBusy: true,
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      selected: null,
      action: null,
      items: [],
      filteredItems: [],
      fields: [
        {
          key: "eventTitle",
          label: "Nombre",
          sortable: true,
        },
        {
          key: "eventDate",
          label: "Fecha",
          sortable: true,
        },
        {
          key: "userCount",
          label: "# Participantes",
          sortable: false,
        },
        {
          key: "confirmedUsers",
          label: "# Confirmados",
          sortable: true,
        }
      ],
      sortBy: "name",
      sortDesc: true,
    };
  },
  computed: {
  },
  methods: {
    formatDate(date) {
      return moment(String(date)).utc().format('DD/MM/YYYY hh:mm A')
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    updateData() {
      this.isBusy = true;
      this.getData();
    },
    getData() {
      this.getContent();
    },
    getUrl(row) {
      const {
        item: { eventId },
      } = row;
      return `/events/${eventId}/details`;
    },
    getContent() {
      getEvents().then(({ status, data }) => {
        if (status === 200) {
          this.items = data;
          this.totalRows = data.length;
          this.isBusy = !this.isBusy;
        }
      });
    },
    onRowSelected(items) {
      this.selectedRows = items;
    },
    clean() {
      this.selected = null;
    },
  },
  beforeMount() {
    this.getContent();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title {
  color: #fff;
  font-size: 24px;
}

.actions {
  background-color: #f2f4f7;
  border-radius: 10px;
  height: 100px;
  width: 100%;
}

.actions-left,
.actions-right {
  flex: 1;
  align-self: center;
}

.actions-left .total {
  color: #0c336b;
}

.actions-left {
  margin-left: 20px;
}
.actions-right {
  margin-right: 20px;
}
.content {
  background-color: #f2f4f7;
  border-radius: 10px;
  height: auto;
  width: 100%;
}
.rounded-circle {
  font-size: 2rem;
  fill: rgb(21, 96, 49);
}
</style>
