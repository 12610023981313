<template>
  <b-container fluid class="main-container">
    <div class="row top" :class="{ onScroll: !view.topOfPage }">
      <Header :cmsActions="cmsActions" />
    </div>
    <div class="row body"></div>
    <div class="row between">
      <p>Te invitamos a vivir esta experiencia</p>
    </div>
    <div class="row register">
      <Register />
    </div>
  </b-container>
</template>

<script>
import Header from "@/components/Header";
import Register from "../Register/Register";

export default {
  name: "LandingPage",
  components: {
    Header,
    Register,
  },
  data() {
    return {
      cmsActions: true,
      view: {
        topOfPage: true,
      },
    };
  },
  mounted() {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-container {
  overflow: hidden;
}

/*.row.body {
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url("~@/assets/images/background1-mobile.png");
  background-attachment: fixed;
  height: 120vh;
  width: 100vw;
}
*/

.body {
  height: 66vh;
  background-image: url("~@/assets/images/080224_AA_GARNIER_HOMEMOBI.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  margin-top: 85px;
}

.between {
  align-items: center;
  position: relative;
  height: 150px;
  background: linear-gradient(-90deg, #0b5229 0%, #21773e 51%, #0b5229 100%);
  color: #fff;
  padding: 15px;
  justify-content: center;
  display: flex;
  margin-top: 5px;
}
.between p {
  font-size: 20px;
  font-weight: bold;
}
.register {
  height: 66vh;
  background-image: url("~@/assets/images/020224_AA_GARNIER_FORM_MOB.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  position: relative;
  margin-top: 5px;
}

/* Small devices (portrait tablets and large phones, 768px and up) */
@media only screen and (min-width: 48em) {
  .body,
  .register {
    height: 100vh;
  }

  .between p {
    font-size: 35px;
    font-weight: bold;
  }
}

/* Large devices (laptops/desktops, 1000px and up) */
@media only screen and (min-width: 62.5em) {
  .body {
    background-image: url("~@/assets/images/080224_AA_GARNIER_HOME@1x.jpg");
    background-size: contain;
    background-position: center;
    height: 81vh;
  }
  .register {
    background-image: url("~@/assets/images/010224_AA_GARNIER_FORM@1x.jpg");
    background-size: contain;
    background-position: center;
    height: 81vh;
  }
}

/* Large devices (laptops , 1200px and up) */
@media only screen and (min-width: 75em) {
  .body {
    background-image: url("~@/assets/images/080224_AA_GARNIER_HOME.jpg");
    background-size: cover;
    background-position: center;
    margin-top: 80px;
    height: 105vh;
  }
  .between {
    display: none;
  }
  .register {
    background-image: url("~@/assets/images/010224_AA_GARNIER_FORM.jpg");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    height: 110vh;
  }
}
</style>
