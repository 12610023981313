<template>
  <div class="header">
    <b-col class="text-left">
      <b-img
        class="logo-extra"
        :src="require('@/assets/images/logo-fvegana.png')"
      ></b-img>
    </b-col>
    <b-col class="text-center">
      <b-img class="logo" :src="require('@/assets/images/logo.png')"></b-img>
    </b-col>
    <b-col class="text-right">
      <b-img
        class="logo-extra"
        :src="require('@/assets/images/logo-cruelty.png')"
      ></b-img>
    </b-col>
  </div>
</template>

<script>
export default {
  name: "appHeader",
  props: ["cmsActions"],
  methods: {},
};
</script>

<style scoped>
.header {
  display: flex;
  align-items: center;
  background: linear-gradient(-90deg, #0b5229 0%, #21773e 51%, #0b5229 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 5rem;
  position: fixed;
  z-index: 1;
}
.title {
  font-size: 2rem;
  font-weight: bolder;
  margin: 15px;
  color: #fff;
}
.logo {
  height: auto;
  width: 150px;
}
.logo-extra {
  height: auto;
  width: 80px;
}

/* Small devices (portrait tablets and large phones, 768px and up) */
@media only screen and (min-width: 48em) {
  .logo {
    width: 220px;
  }
  .logo-extra {
    margin: 15px;
    width: 150px;
  }
}

/* Large devices (laptops , 1200px and up) */
@media only screen and (min-width: 75em) {
  .logo {
    width: 280px;
  }
}
</style>
