import axios from "axios";
import { config } from "../config";

export const apiInstance = axios.create({
  baseURL: config.cmsApi,
  headers: {
    common: {
      "Content-Type": "application/json",
      "x-api-key": config.apikey
    }
  },
});
