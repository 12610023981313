import { apiInstance } from "@/api";

export const updateStatusUser = async (uid) => {
  const requestOptions = {
    method: "PUT",
    url: `users`,
    data: { uid },
  };
  return await apiInstance(requestOptions);
};
