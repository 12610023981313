import Vue from "vue";
import VueRouter from "vue-router";
import { Auth } from "aws-amplify";

import Dashboard from "../views/Dashboard/Dashboard.vue";
import Landing from "../views/Landing/Landing.vue";
import SignIn from "../views/SignIn/SignIn.vue";
import EventList from "../views/Events/EventList.vue";
import EventDetails from "../views/Events/EventDetails.vue";
import UserDetails from "../views/Users/UserDetails.vue";

import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Landing,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/login",
    component: SignIn,
  },
  {
    path: "/details",
    component: UserDetails,
  },
  {
    path: "/cms",
    component: Dashboard,
    redirect: "/events",
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/events",
        component: EventList,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/events/:id/details",
        component: EventDetails,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  let routerAuthCheck = false;
  const localSession = Object.entries(localStorage).filter((elm) =>
    elm[0].includes("accessToken")
  );
  if (localSession.length) {
    const session = await Auth.currentAuthenticatedUser().catch((e) => {
      console.log("ERROR:", e);
    });
    if (session) {
      const jwt = session.getSignInUserSession().getIdToken();
      routerAuthCheck =
        new Date(jwt.payload.iat * 1000) < new Date(jwt.payload.exp * 1000);
      store.commit("auth/user", session);
    }
  }
  store.commit("auth/setUserIsAuthenticated", routerAuthCheck);
  if (requiresAuth) {
    if (routerAuthCheck) {
      next();
    } else {
      store.commit("auth/user", null);
    }
  }
  next();
});

export default router;
