<template>
  <div class="container-fluid">
    <div class="row vh-100">
      <div class="col-md-3 m-3 background">
        <b-img alt="Antiagency" :src="logo" class="logo align-middle pt-4" />
      </div>
      <div class="col-md-5 text-left my-auto">
        <b-form class="m-5" @submit="onSubmit">
          <h1 class="text-center mb-5 login_title">Bienvenido</h1>
          <div class="form-group">
            <b-icon icon="person-circle" variant="dark"></b-icon>
            <label class="m-2 font-weight-bold">Email</label>
            <b-form-input
              class="form-control form-control-lg"
              id="email"
              v-model="signInEmail"
              type="email"
              placeholder="Ingresa tu email"
            ></b-form-input>
          </div>
          <div class="form-group">
            <b-icon icon="key" variant="dark"></b-icon>
            <label class="m-2 font-weight-bold">Contraseña</label>
            <b-form-input
              class="form-control form-control-lg"
              id="password"
              v-model="signInPassword"
              type="password"
              placeholder="Ingresa tu contraseña"
            ></b-form-input>
          </div>
          <b-alert dismissible :show="getError !== ''" variant="danger">
            {{ getError }}
          </b-alert>
          <b-col class="pb-2 text-right p-0">
            <b-button
              class="btn--blue"
              variant="primary"
              type="submit"
              :disabled="signInEmail === '' || signInPassword === ''"
            >
              <b-spinner small type="grow" v-show="isLoading"></b-spinner>
              {{ btnLabel }}
            </b-button>
          </b-col>
        </b-form>
      </div>
      <div class="col-md-3"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import logo from "@/assets/images/logo.png";

export default {
  name: "SignInPage",
  data() {
    return {
      signInEmail: "",
      signInPassword: "",
      btnLabel: "Entrar",
      isLoading: false,
      logo,
    };
  },
  methods: {
    async onSubmit(e) {
      e.preventDefault();
      this.isLoading = true;
      this.btnLabel = "Cargando...";
      await this.$store.dispatch("auth/login", {
        email: this.signInEmail,
        password: this.signInPassword,
      });
      this.isLoading = false;
      this.btnLabel = "Entrar";
    },
  },
  computed: {
    ...mapGetters(["auth/authorized"]),
    getError() {
      return this.$store.getters["auth/authorized"].loginError;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.background {
  background-color: rgb(21, 96, 49);
  border-radius: 10px;
}

.login_title {
  color: #000000;
}

.logo {
  margin: auto 0;
  height: auto;
  width: 200px;
}
</style>
