<template>
  <b-container class="register" fluid>
    <b-row fluid class="header-title">
      <p>Te invitamos a vivir esta experiencia</p>
    </b-row>
    <b-row class="register-title">
      <div class="title">
        <img
          src="@/assets/images/titulo-casa-garnier.png"
          alt="Casa Vitamina C Garnier"
          class="title-img"
        />
      </div>
    </b-row>
    <b-row class="form-content">
      <b-form @submit="onSubmit" class="form-container">
        <div class="form-row mt-3">
          <b-input-group class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="person-fill"></b-icon>
            </b-input-group-prepend>
            <b-form-input
              class="form-control form-control-md"
              id="code"
              v-model="name"
              type="text"
              required
              placeholder="Nombre"
            ></b-form-input>
          </b-input-group>
        </div>
        <div class="form-row mt-3">
          <b-input-group class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="people-fill"></b-icon>
            </b-input-group-prepend>
            <b-form-input
              class="form-control form-control-md"
              id="code"
              v-model="lastName"
              type="text"
              required
              placeholder="Apellidos"
            ></b-form-input>
          </b-input-group>
        </div>
        <div class="form-row mt-3">
          <b-input-group class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="envelope-fill"></b-icon>
            </b-input-group-prepend>
            <b-form-input
              class="form-control form-control-md"
              id="email"
              v-model="email"
              type="email"
              required
              placeholder="Correo"
            ></b-form-input>
          </b-input-group>
        </div>
        <div class="form-row mt-3">
          <b-input-group class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="calendar"></b-icon>
            </b-input-group-prepend>
            <b-form-select
              v-model="selectedDate"
              :options="optionsDate"
              required
            ></b-form-select>
          </b-input-group>
        </div>
        <div class="form-row mt-3">
          <b-input-group class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="clock"></b-icon>
            </b-input-group-prepend>
            <b-form-select
              :disabled="!selectedDate"
              v-model="selectedHour"
              :options="optionsHour"
              required
            ></b-form-select>
          </b-input-group>
        </div>
        <div class="form-row mt-3">
          <b-button
            :disabled="isDisabled"
            block
            variant="outline-warning"
            class="btn--rounded btn--register"
            @click="onSubmit"
            >{{ btnLabel }}
          </b-button>
        </div>
      </b-form>
    </b-row>
  </b-container>
</template>

<script>
import { getAvailableEvents, setEventUser } from "@/api/events";

export default {
  name: "RegisterPage",
  components: {},
  data() {
    return {
      isBusy: true,
      isDisabled: false,
      btnLabel: "Registrarse",
      name: "",
      lastName: "",
      email: "",
      selectedDate: "",
      selectedHour: "",
      optionsDate: [],
      optionsHour: [],
      items: [],
    };
  },
  watch: {
    selectedDate: function (selectedDate) {
      const dates = this.items;
      const availableHours = [];
      const dayHours = dates.filter(
        (date) => date.eventDate.split("T")[0] === selectedDate
      );
      dayHours.map((day) => {
        if (day.available > 0) {
          const hourWSeconds = day.eventDate.split("T")[1].split(".")[0];
          const hour = hourWSeconds.split(":").slice(0, 2).join(":");
          availableHours.push(hour);
        }
      });
      this.optionsHour = availableHours;
    },
  },
  methods: {
    formatDate(date) {
      const options = {
        year: "numeric",
        month: "short",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return new Date(date).toLocaleDateString("es-MX", options);
    },
    showEventDetails(eventData) {
      this.getDetails(eventData);
    },
    getData() {
      getAvailableEvents().then(async ({ status, data }) => {
        if (status === 200) {
          const dates = [];
          this.items = data;

          data.map((event) => {
            const date = event.eventDate.split("T")[0];
            if (!dates.includes(date)) dates.push(date);
          });

          this.optionsDate = dates;
        }
        this.isBusy = !this.isBusy;
      });
    },
    updateData() {
      this.isBusy = true;
      this.getData();
    },
    onSubmit() {
      this.isDisabled = true;
      const { name, lastName, email, selectedDate, selectedHour } = this;
      const registerdata = {
        name,
        lastName,
        eventDate: selectedDate,
        eventHour: selectedHour,
        email,
      };
      if (email && name && lastName && selectedDate && selectedHour) {
        setEventUser(registerdata)
          .then(({ status }) => {
            if (status === 200) {
              this.$emit("updateData");
              this.makeToast("success");
              this.clean();
            } else {
              this.makeToast("warning");
            }
            this.isDisabled = false;
          })
          .catch(({ response }) => {
            this.makeToast("danger", response.data);
            this.clean();
          });
      }
    },
    spaceAvailable(eventData) {
      const available = eventData.eventMaxUsers - eventData.userCount;
      const label = `${available} / ${eventData.eventMaxUsers}`;
      return label;
    },
    makeToast(variant = null, response = null) {
      let title = "Error";
      if (!response) {
        response = "Ocurrio un error, intenta más tarde.";
      }
      if (variant === "success") {
        response =
          "Registro exitoso. En breve recibiras un correo de confirmación. Si no lo ves en tu bandeja de entrada, revisa tu spam.";
        title = "Éxito";
      }
      this.$bvToast.toast(response, {
        title,
        variant,
        solid: true,
      });
    },
    clean() {
      this.name = "";
      this.lastName = "";
      this.email = "";
      this.selectedDate = "";
      this.selectedHour = "";
      this.btnLabel = "Registrarme";
    },
  },
  beforeMount() {
    this.getData();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form-content {
  background: #21773e;
  box-shadow: 10px 20px 6.3px 0px rgba(0, 0, 0, 0.15);
  padding: 30px;
  width: 300px;
  position: relative;
  opacity: 0.9;
  bottom: -5%;
}

.form-container {
  padding-top: 2rem;
}
.input-group-text {
  background-color: #fff;
}

.register-title {
  position: absolute;
  top: 1.5rem;
  right: 10%;
  width: 300px;
  z-index: 1;
}

.title-img {
  width: 275px;
}

.header-title {
  position: relative;
  width: 92%;
  height: 55px;
  background: linear-gradient(-90deg, #0b5229 0%, #21773e 51%, #0b5229 100%);
  color: #fff;
  padding: 15px;
  margin-top: 35%;
  justify-content: center;
  display: none;
}

.header-title p {
  font-size: 16px;
  font-weight: bold;
}

/* Small devices (portrait tablets and large phones, 768px and up) */
@media only screen and (min-width: 48em) {
  .register-title {
    top: 6rem;
    right: 30%;
    width: 300px;
    z-index: 1;
  }

  .title-img {
    width: 400px;
  }
}

/* Large devices (laptops/desktops, 1000px and up) */
@media only screen and (min-width: 62.5em) {
  .form-container {
    padding-top: 0;
  }
  .register-title {
    top: 2rem;
    right: 20%;
    width: 280px;
  }
  .register-title .title {
    font-size: 2.2rem;
  }
  .header-title {
    height: 60px;
    padding: 10px;
    margin-top: 14%;
  }
  .header-title p {
    font-size: 28px;
  }
}

/* Large devices (laptops , 1200px and up) */
@media only screen and (min-width: 75.5em) {
  .register-title {
    top: 38%;
    right: 35%;
    transform: translate(-50%, -50%);
  }
  .form-content {
    width: 400px;
  }
  .form-container {
    width: 100%;
  }

  .header-title {
    position: absolute;
    width: 55%;
    height: 90px;
    background: linear-gradient(-90deg, #0b5229 0%, #21773e 51%, #0b5229 100%);
    color: #fff;
    padding: 15px;
    margin-top: 10%;
    justify-content: center;
    display: flex;
    left: 0;
  }

  .header-title p {
    font-size: 42px;
    height: auto;
    color: #fff;
  }
}

@media only screen and (min-width: 85.375em) {
  .header-title {
    height: 100px;
    padding: 18px;
    margin-top: 12%;
  }

  .header-title p {
    font-size: 38px;
  }
  .register-title {
    width: 350px;
  }
  .register-title .title {
    font-size: 2.2rem;
  }

  .form-content {
    left: 25%;
  }
}
/* Large devices (large laptops and desktops, 1600px and up) */
@media only screen and (min-width: 105em) {
  .register-title {
    right: 31%;
    width: 450px;
  }
  .register-title .title {
    font-size: 2.8rem;
  }
  .form-content {
    width: 500px;
  }
}
</style>
