import { apiInstance } from "@/api";
export const getEvents = async () => {
  const requestOptions = {
    method: "GET",
    url: `events`,
  };
  return await apiInstance(requestOptions);
};

export const setEventUser = async (data) => {
  const requestOptions = {
    method: "POST",
    url: `events`,
    data,
  };
  return await apiInstance(requestOptions);
};

export const getEventDetails = async (eventId) => {
  const requestOptions = {
    method: "GET",
    url: `events/${eventId}`,
  };
  return await apiInstance(requestOptions);
};

export const getAvailableEvents = async () => {
  const requestOptions = {
    method: "GET",
    url: `available`,
  };
  return await apiInstance(requestOptions);
};

export const getUserEvent = async (uid) => {
  const requestOptions = {
    method: "GET",
    url: `details?id=${uid}`,
  };
  return await apiInstance(requestOptions);
}
