<template>
  <b-container class="user-details" fluid>
    <b-row class="top" :class="{ onScroll: !view.topOfPage }">
      <Header></Header>
    </b-row>
    <b-row class="detail-content">
      <b-jumbotron bg-variant="light" class="details" text-variant="black">
        <template #header>
          <label class="title">Garnier Express Aclara</label>
        </template>
        <template #lead>
          {{ formatDate(userData.event_date) }}
        </template>
        <hr class="my-4" />
        <div v-show="isValid">
          <p>{{ `${userData.name} ${userData.last_name}` }}</p>
          <p>{{ userData.email }}</p>
        </div>
      </b-jumbotron>
    </b-row>
  </b-container>
</template>

<script>
import Header from "@/components/Header";

import { getUserEvent } from "@/api/events";
import moment from "moment-timezone";

export default {
  name: "UserDetails",
  components: { Header },
  data() {
    return {
      isBusy: true,
      isValid: true,
      userData: [],
      view: {
        topOfPage: true,
      },
    };
  },
  methods: {
    handleScroll() {
      if (window.pageYOffset > 0) {
        if (this.view.topOfPage) this.view.topOfPage = false;
      } else {
        if (!this.view.topOfPage) this.view.topOfPage = true;
      }
    },
    formatDate(date) {
      if (!date) return "Código Invalido";
      return moment(String(date))
        .utc()
        .locale("es-MX")
        .format("DD MMMM YYYY, hh:mm A");
    },
    showEventDetails(eventData) {
      this.getDetails(eventData);
    },
    getData(uid) {
      getUserEvent(uid).then(async ({ status, data }) => {
        if (status === 200) {
          this.isValid = data.user.length > 0;
          if(this.isValid) {
            this.userData = data.user[0];
          }
        }
        this.isBusy = !this.isBusy;
      });
    },
    updateData() {
      this.isBusy = true;
      this.getData();
    },
  },
  beforeMount() {
    if (this.$route.query && this.$route.query.id) {
      const uid = this.$route.query.id;
      this.getData(uid);
    } else {
      this.$router.push("/");
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.top {
  height: 80px;
}
.detail-header,
.detail-footer {
  background-size: cover;
  height: 50vh;
}

.detail-content {
  margin-top: 10%;
}

.details {
  margin: 0 auto;
  max-height: 60%;
  background-color: #f4f4f4;
}

.details .title {
  font-weight: bold;
  font-size: 35px;
  color: #20753d;
}

/* Small devices (portrait tablets and large phones, 768px and up) */
@media only screen and (min-width: 48em) {
}
/* Large devices (laptops , 1200px and up) */
@media only screen and (min-width: 75em) {
}
</style>
