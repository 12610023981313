import { Amplify, Auth } from "aws-amplify";
import awsconfig from "./../../aws-exports";
import router from "../../routes";
import { apiInstance } from "@/api";

Amplify.configure(awsconfig);

const store = { namespaced: true };

store.state = {
  auth: {
    authorized: false,
    user: null,
    loginError: "",
  },
};

// Mutations ==================

store.mutations = {
  user(state, user) {
    if (user) {
      apiInstance.defaults.headers.common["Authorization"] = user
        .getSignInUserSession()
        .getIdToken()
        .getJwtToken();
    } else {
      router.push("/");
    }
    state.auth.authorized =
      !!user && user.attributes && user.attributes.email_verified;
    state.auth.user = user;
  },
  confirm(state, showConfirm) {
    state.auth.confirm = !!showConfirm;
  },
  setUserIsAuthenticated(state, isAuthorized) {
    state.auth.authorized = isAuthorized;
  },
  setLoginError(state, value) {
    state.auth.loginError = value;
  },
};

// Actions ====================

store.actions = {
  async login({ dispatch, state }, { email, password }) {
    state.auth.loginError = "";
    try {
      await Auth.signIn(email, password);
    } catch (err) {
      console.log(`Login Error [${err}]`);
      if (err) state.auth.loginError = err.message || err;
      return;
    }
    await dispatch("fetchUser");
  },

  async authState({ commit, dispatch }, state) {
    if (state === "signedIn") await dispatch("fetchUser");
    else commit("user", null);
  },

  async fetchUser({ commit, dispatch }) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      console.log("fetchUser", user);
      const expires =
        user.getSignInUserSession().getIdToken().payload.exp -
        Math.floor(new Date().getTime() / 1000);
      setTimeout(async () => {
        console.log("Renewing Token");
        await dispatch("fetchUser");
      }, expires * 1000);
      commit("user", user);
      const route = user ? "/cms" : "/";
      router.push(route);
    } catch (err) {
      commit("user", null);
    }
  },

  async logout({ commit }) {
    await Auth.signOut();
    commit("user", null);
  },
};

store.getters = {
  authorized(state) {
    return state.auth;
  },
};

export default store;
