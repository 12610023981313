<template>
  <div class="categories">
    <div class="row justify-content-between align-middle">
      <p class="title font-weight-light">
        Usuarios registrados:
        <label class="font-weight-bold">{{ eventName }}</label>
      </p>
    </div>
    <div class="row actions mt-4 shadow p-3 mb-5 bg-white rounded">
      <div class="actions-left font-weight-bold">
        <b-input-group>
          <b-input-group-prepend is-text>
            <b-icon icon="search"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            type="search"
            v-model="filter"
            placeholder="Buscar..."
          ></b-form-input>
        </b-input-group>
      </div>
      <div class="actions-right text-right">
        <b-button variant="outline-danger" class="mr-3" @click="$router.go(-1)">
          Regresar <b-icon icon="arrow-return-left" aria-hidden="true" />
        </b-button>
      </div>
    </div>
    <div class="row content mt-4 shadow p-3 mb-5 bg-white rounded">
      <div class="description">
        <p>
          <b-icon icon="info-square" variant="info" class="mr-1" />
          <label> Listado de personas registradas para el evento. </label>
        </p>
      </div>
      <b-table
        small
        bordered
        hover
        :items="items"
        :fields="fields"
        class="mt-4"
        :per-page="perPage"
        :current-page="currentPage"
        :busy="isBusy"
        show-empty
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :filter="filter"
        @filtered="onFiltered"
        ref="contentTable"
        responsive
      >
        <template #thead-top>
          <b-tr class="theader">
            <b-th colspan="5">
              <b-icon
                icon="columns-gap"
                class="icon rounded-circle p-2"
                variant="secondary"
              ></b-icon>
              <label class="total">{{ totalRows }}</label>
              <label class="total-title">Registro(s)</label>
            </b-th>
          </b-tr>
        </template>
        <template #cell(status)="row">
          <b-badge
            :variant="row.value === 'Pendiente' ? 'warning' : 'success'"
            >{{ row.value }}</b-badge
          >
        </template>
        <template #cell(uid)="row">
          <b-button
            v-if="row.item.status === 'Pendiente'"
            size="sm"
            variant="outline-success"
            class="mb-2"
            @click="showConfirmationModal(row.item)"
          >
            <b-icon icon="calendar-check" aria-hidden="true"></b-icon> Confirmar
          </b-button>
          <label v-else>-</label>
        </template>
        <template #table-busy>
          <div class="text-center text-info my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Cargando...</strong>
          </div>
        </template>
        <template #empty>
          <div class="text-center m-4">No se encontraron resultados</div>
        </template>
      </b-table>
      <b-pagination
        pills
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="right"
      />
    </div>
    <b-modal id="confirmationModal" ref="confirmation-modal">
      ¿Confirmar asistencia de
      <label class="font-weight-bold">
        {{ selected?.name }} {{ selected?.last_name }}</label
      >
      ?
      <template #modal-footer>
        <div>
          <b-button
            variant="outline-secondary"
            class="btn--rounded mr-2"
            size="sm"
            @click="$bvModal.hide('confirmationModal')"
            >Cancelar
          </b-button>
          <b-button
            :disabled="isBusy"
            variant="outline-success"
            class="btn--rounded btn--add"
            size="sm"
            @click="onConfirm"
            >Confirmar
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { getEventDetails } from "@/api/events";
import { updateStatusUser } from "@/api/users";

export default {
  name: "EventDetail",
  components: {},
  data() {
    return {
      eventId: null,
      eventName: "",
      isBusy: true,
      filter: null,
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      sortBy: "status",
      sortDesc: true,
      selected: null,
      action: null,
      items: [],
      fields: [
        {
          key: "name",
          label: "Nombre(s)",
          sortable: true,
        },
        {
          key: "last_name",
          label: "Apellido(s)",
          sortable: true,
        },
        {
          key: "email",
          label: "Email",
          sortable: false,
        },
        {
          key: "status",
          label: "Estatus",
          sortable: true,
          class: "text-center",
        },
        {
          key: "uid",
          label: "Acciones",
          sortable: false,
          class: "text-center",
        },
      ],
    };
  },
  computed: {},
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    updateContentData() {
      this.isBusy = true;
      this.getData();
    },
    getData() {
      getEventDetails(this.eventId).then(({ status, data }) => {
        if (status === 200) {
          const { event, users } = data;
          const items = users || [];
          this.items = items;
          this.eventName = event[0].eventTitle;
          this.totalRows = items.length;
          this.isBusy = !this.isBusy;
        }
      });
    },
    clean() {
      this.selected = null;
    },
    showConfirmationModal(item) {
      this.selected = item;
      this.$refs["confirmation-modal"].show();
    },
    async onConfirm() {
      this.isBusy = true;
      await updateStatusUser(this.selected.uid).then(
        ({ status }) => {
          if (status === 200) {
            this.makeToast("success");
            this.$refs["confirmation-modal"].hide();
          } else {
            this.makeToast("error");
          }
          this.getData();
        }
      );
    },
    makeToast(variant = null, response = null) {
      let title = "Error";
      if (!response) {
        response = "Ocurrio un error, intenta más tarde.";
      }
      if (variant === "success") {
        response = "Confirmación registrada correctamente.";
        title = "Éxito";
      }
      this.$bvToast.toast(response, {
        title,
        variant,
        solid: true,
      });
    },
  },
  beforeMount() {
    const id = this.$router.history.current.params.id;
    if (id) {
      this.eventId = id;
      this.getData();
    }
  },
  mounted() {
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      if (modalId === "confirmationModal") {
        this.clean();
      }
    });
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title {
  color: #fff;
  font-size: 26px;
}

.actions {
  background-color: #f2f4f7;
  border-radius: 10px;
  height: 100px;
  width: 100%;
}

.actions-left,
.actions-right {
  flex: 1;
  align-self: center;
}

.actions-left .total {
  color: #0c336b;
}

.actions-left {
  margin-left: 20px;
}
.actions-right {
  margin-right: 20px;
}

.content {
  background-color: #f2f4f7;
  border-radius: 10px;
  height: auto;
  width: 100%;
}

.rounded-circle {
  font-size: 2.2rem;
  fill: #fff;
}
</style>
